import React, { Component } from "react"
import { Row, Col, Modal, Button } from "react-bootstrap"
import "../styles/main.scss"
import Fbdark from "../images/fb-dark.svg"
import Indark from "../images/in-dark.svg"
import Close from "../images/close.svg"
import {
  FacebookShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon
} from "react-share";
import parse from 'html-react-parser';
import Advice1 from "../images/advice-add-001.jpg"
import Advice2 from "../images/advice-add-002.jpg"
import Advice3 from "../images/advice-add-003.jpg"
import Advice4 from "../images/advice-add-004.jpg"
import Advice5 from "../images/advice-add-005.jpg"

import Brand from "../images/brand.png"
import avatar from '../images/avatar.png'
import {
  ALERT_MSG_ERROR,
  AUTH_TOKEN_KEY,
  DOMAIN_API,
  PARAM_ID,
  translate,
  URL_GETONE_ADVANTAGE,
  URL_GETONE_ADVICE
} from "../utils"
import axios from "axios"
import ModalAlert from "../components/modalAlert"

class Advantage extends Component {
  state = {
    advantage: null,
    showAlert: false,
    alertTitle: '',
    alertMessage: '',
  }

  componentDidMount() {
    this.getAdvantage();
  }

  getAdvantage = () => {
    if (this.props.location) {
      const query = new URLSearchParams(this.props.location.search);
      if (query.get(PARAM_ID) !== null) {
        const id = query.get(PARAM_ID);
        const token = localStorage.getItem(AUTH_TOKEN_KEY)
        const header = {
          headers: { Authorization: `Bearer ${token}` }
        };
        const data = {
          id: id,
        };
        axios.post(URL_GETONE_ADVANTAGE,data,header)
          .then(res => {
            if (res.data) {
              this.setState({ advantage: res.data })
            }
          })
          .catch(err => {
            if (err.response && err.response.data) {
              console.log(err.response.data);
              this.setState({
                showAlert: true,
                alertTitle: ALERT_MSG_ERROR,
                alertMessage: err.response.data.message,
              })
            }
            console.log(err)
          });

      }else {
        this.setState({
          showAlert: true,
          alertTitle: ALERT_MSG_ERROR,
          alertMessage: "id of advantage not found.",
        })
        //alert('id of advice not found.')
      }
    }
  }

  render() {
    const {advantage,showAlert,alertMessage,alertTitle} = this.state;
    let s420x350Array = [];
    let s866x350Array = [];

    if (advantage) {
      if (advantage.s420x350.length > 0) {
        s420x350Array = advantage.s420x350
      }
      if (advantage.s866x350.length > 0) {
        s866x350Array = advantage.s866x350;
      }
    }

    return (
      <div className="modal-wrap">
        <Modal.Dialog>
          <Modal.Header
            className=""
            style={{
              backgroundImage: `url("${(advantage)?DOMAIN_API()+advantage.s2500x500:"https://source.unsplash.com/1920x400/?mountain"}")`,
              //backgroundImage: `url("https://source.unsplash.com/1920x400/?mountain")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "auto",
              color: "white",
            }}
          >
            <a className="closeButton" href={"/feed"}>
              <img
                width={28}
                height={28}
                className="img-fluid"
                src={Close}
                alt=""
              />
            </a>
            <Modal.Title>
              <span className="title-tag d-inline">{translate("avantages")}</span>
              <span className="d-block">
                {""}
            </span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col md="7" className="pr-5">
                <div className="orange-widgets-box-profile rounded-lg mt-4 mb-5  text-center">
                  <Row className="align-items-center">
                    <Col xl="6">
                      <h1 className="h1-title">
                        {translate('ik wil van dit voordeel genieten'+"!")}
                      </h1>
                    </Col>
                    <Col xl="6">
                      <Button onClick={() => alert('Coming soon!')} variant="outline-secondary">{translate('Ik profiteer er van'+"!")}</Button>
                    </Col>
                  </Row>
                </div>
                <h2>{(advantage)?advantage.title:''}</h2>
                <h3>{(advantage)?advantage.subtitle:''}</h3>
                <p style={{whiteSpace: 'pre-wrap'}}>{ advantage ? parse(advantage.description) : ''}</p>
                {/*<p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  volutpat ipsum id bibendum commodo. Etiam tincidunt ante ut ante
                  dignissim viverra. Proin pharetra lectus vel augue elementum, at
                  egestas dui iaculis. Mauris ac sem odio. Vivamus id elit sed
                  tellus condimentum posuere. Etiam conv
                  <strong>
                    allis ac ipsum non tempus. Vivamus orci erat, elementum eget
                    malesuada ut, fringilla ut elit. Donec velit mi, malesuada
                    vitae convallis eleifend, moles
                  </strong>
                  tie eget nulla.
                </p>
                <p>
                  Praesent pulvinar sollicitudin est in tristique. Nulla purus
                  leo, varius non pharetra nec, iaculis eu velit. Sed justo mi,
                  molestie sit amet nisl nec, cursus vestibulum sem. Nunc non
                  volutpat erat, nec mattis ex. Sed fermentum elit sem, id luctus
                  nisl fermentum ut. Aliquam efficitur ac
                  <strong>
                    {" "}
                    massa in venenatis. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nullam malesuada aliquet nulla, id sodales
                    mi. Sed congue nunc sem, non tincidunt velit lobortis eu.
                    Donec eget est ac nibh vestibulum laoreet eu aliquam dolor.
                    Etiam venenatis mauris et malesuada gravida. Sed interdum eros
                    id sem fermentum, eu lacinia ligula vulputate.{" "}
                  </strong>
                </p>*/}
                <a onClick={() => alert('Coming soon!')} className="btn btn-outline-danger mb-4">
                  {translate("I want this advantage")}
                </a>
                <Row>

                  {s420x350Array.map(item => (
                    <Col xl="6">
                      <div className="object-fill">
                        <img className="img-fluid" src={DOMAIN_API()+item} alt="" />
                      </div>
                    </Col>
                  ))}
                  {s866x350Array.map(item => (
                    <Col xl="12">
                      <div className="object-fill">
                        <img className="img-fluid" src={DOMAIN_API()+item} alt="" />
                      </div>
                    </Col>
                  ))}
                  {/*<Col xl="6">
                    <div className="object-fill">
                      <img className="img-fluid" src={Advice1} alt="" />
                    </div>
                  </Col>
                  <Col xl="6">
                    <div className="object-fill">
                      <img className="img-fluid" src={Advice2} alt="" />
                    </div>
                  </Col>
                  <Col xl="6">
                    <div className="object-fill">
                      <img className="img-fluid" src={Advice3} alt="" />
                    </div>
                  </Col>
                  <Col xl="6">
                    <div className="object-fill">
                      <img className="img-fluid" src={Advice4} alt="" />
                    </div>
                  </Col>*/}
                  {/*<Col xl="12">
                    <div className="object-fill">
                      <img className="img-fluid" src={Advice5} alt="" />
                    </div>
                  </Col>*/}
                </Row>
                <a onClick={() => alert('Coming soon!')} className="btn btn-outline-danger" >
                  {translate('I want this advantage')}
                </a>
              </Col>
              <Col md="4" className="offset-md-1 modal-sidebar">
                <h3>Partner</h3>
                <img style={{width: '150px'}} className="img-fluid" src={avatar} alt="" />
                <div className="my-4">
                  <h4>Bedrijf S.A.</h4>
                  <a onClick={() => alert('Coming soon!')} className="btn btn-outline-dark">
                    {'+ '+translate('meer weten over de partner')}
                  </a>
                </div>
                {/*<div className="my-4">
                  <h3 className="mb-0">Advantage details</h3>
                  <small>Countdown: 15 days _ 3 hours _ 26 min</small>
                </div>*/}
                <h3>{translate('delen')}</h3>
                <ul className="list-none m-0 ui-social-linline">
                  <li>
                    <FacebookShareButton
                      url={'www.enter-business.com'}
                      quote={'message particulier'}
                      hashtag={'#ENTERBUSINESS'}>
                      <FacebookIcon size={25} round={false} />
                    </FacebookShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton
                      url={'www.enter-business.com'}
                    >
                      <LinkedinIcon size={25} round={false} />
                    </LinkedinShareButton>
                  </li>
                </ul>
              </Col>
            </Row>
          </Modal.Body>
          {(showAlert)?
            <ModalAlert
              alertTitle={alertTitle}
              alertMessage={alertMessage}
              closeModal={() => this.setState({showAlert: false})}
            />:null
          }
        </Modal.Dialog>
      </div>
    )
  }
}
/*
const ModalFour = () => {
  return (
    <div className="modal-wrap">
      <Modal.Dialog>
        <Modal.Header
          className="modal-blue-alpha"
          closeButton
          style={{
            backgroundImage: `url("https://source.unsplash.com/1920x400/?mountain")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "auto",
            color: "white",
          }}
        >
          <Modal.Title>
            <span className="title-tag d-inline">Avantages</span>
            <span className="d-block">
              Le coworking, ou comment développer son réseau?
            </span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md="7" className="pr-5">
              <div className="orange-widgets-box rounded-lg mt-4 mb-5  text-center">
                <Row className="align-items-center">
                  <Col xl="6">
                    <h1 className="h1-title">
                      Ik wil van dit voordeel genieten!
                    </h1>
                  </Col>
                  <Col xl="6">
                    <Button variant="outline-secondary">J’en profite!</Button>
                  </Col>
                </Row>
              </div>
              <h2>Reclaim Your Creative Confidence</h2>
              <h3>Example of advantage</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                volutpat ipsum id bibendum commodo. Etiam tincidunt ante ut ante
                dignissim viverra. Proin pharetra lectus vel augue elementum, at
                egestas dui iaculis. Mauris ac sem odio. Vivamus id elit sed
                tellus condimentum posuere. Etiam conv
                <strong>
                  allis ac ipsum non tempus. Vivamus orci erat, elementum eget
                  malesuada ut, fringilla ut elit. Donec velit mi, malesuada
                  vitae convallis eleifend, moles
                </strong>
                tie eget nulla.
              </p>
              <p>
                Praesent pulvinar sollicitudin est in tristique. Nulla purus
                leo, varius non pharetra nec, iaculis eu velit. Sed justo mi,
                molestie sit amet nisl nec, cursus vestibulum sem. Nunc non
                volutpat erat, nec mattis ex. Sed fermentum elit sem, id luctus
                nisl fermentum ut. Aliquam efficitur ac
                <strong>
                  {" "}
                  massa in venenatis. Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit. Nullam malesuada aliquet nulla, id sodales
                  mi. Sed congue nunc sem, non tincidunt velit lobortis eu.
                  Donec eget est ac nibh vestibulum laoreet eu aliquam dolor.
                  Etiam venenatis mauris et malesuada gravida. Sed interdum eros
                  id sem fermentum, eu lacinia ligula vulputate.{" "}
                </strong>
              </p>
              <a className="btn btn-outline-danger mb-4" href="#">
                I want this advantage
              </a>
              <Row>
                <Col xl="6">
                  <div className="object-fill">
                    <img className="img-fluid" src={Advice1} alt="" />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="object-fill">
                    <img className="img-fluid" src={Advice2} alt="" />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="object-fill">
                    <img className="img-fluid" src={Advice3} alt="" />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="object-fill">
                    <img className="img-fluid" src={Advice4} alt="" />
                  </div>
                </Col>
                <Col xl="12">
                  <div className="object-fill">
                    <img className="img-fluid" src={Advice5} alt="" />
                  </div>
                </Col>
              </Row>
              <a className="btn btn-outline-danger" href="#">
                I want this advantage
              </a>
            </Col>
            <Col md="4" className="offset-md-1 modal-sidebar">
              <h3>Partner</h3>
              <img className="img-fluid" src={Brand} alt="" />
              <div className="my-4">
                <h4>Proximus S.A.</h4>
                <Link to="#" className="btn btn-outline-dark">
                  + Meer weten over de partner
                </Link>
              </div>
              <div className="my-4">
                <h3 className="mb-0">Advantage details</h3>
                <small>Countdown: 15 days _ 3 hours _ 26 min</small>
              </div>
              <h3>Delen</h3>
              <ul className="list-none m-0 ui-social-linline">
                <li>
                  <Link to="">
                    <img
                      width={17}
                      height={17}
                      className="img-fluid"
                      src={Fbdark}
                      alt="Facebook"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <img
                      width={17}
                      height={17}
                      className="img-fluid"
                      src={Indark}
                      alt="Linkedin"
                    />
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  )
}
*/
export default Advantage;
